import React from 'react'
import Button from '../../../components/Button/Button'
import { useWalletModal } from '../../WalletModal'
import { Login } from '../../WalletModal/types'

interface Props {
	account?: string
	login: Login
	logout: () => void
}

const UserBlock: React.FC<Props> = ({ account, login, logout }) => {
	const { onPresentConnectModal, onPresentAccountModal } = useWalletModal(login, logout, account)
	const accountEllipsis = account
		? `${account.substring(0, 4)}...${account.substring(account.length - 4)}`
		: null
	return (
		<div>
			{account ? (
				<button
					className="fc-button -white _bdrd-max -small _pdr-8px"
					style={{ boxShadow: '0px 2px 6px rgba(0,0,0,0.1)' }}
					type="button"
					onClick={() => {
						onPresentAccountModal()
					}}
				>
					{accountEllipsis}
					<div className="_w-32px _mgl-4px">
						<i className="far fa-cog" />
					</div>
				</button>
			) : (
				<button
					className="fc-button -primary -raised _bdrd-max -small"
					type="button"
					onClick={() => {
						onPresentConnectModal()
					}}
				>
					Connect Wallet
				</button>
			)}
		</div>
	)
}

export default React.memo(
	UserBlock,
	(prevProps, nextProps) => prevProps.account === nextProps.account,
)
