import { Colors } from './types'

export const baseColors = {
	failure: 'hsl(9, 90%, 60%)',
	primary: '#36B5FF',
	primaryBright: '#36B5FF',
	primaryDark: '#006CAB',
	secondary: '#febc2f',
	success: '#31D0AA',
	warning: '#FFB237',
}

export const brandColors = {
	binance: '#F0B90B',
}

export const lightColors: Colors = {
	...baseColors,
	...brandColors,
	background: '#FAF9FA',
	backgroundDisabled: '#E9EAEB',
	backgroundAlt: 'hsl(222, 30%, 100%)',
	cardBorder: '#E7E3EB',
	contrast: '#191326',
	dropdown: '#F6F6F6',
	dropdownDeep: '#EEEEEE',
	invertedContrast: '#FFFFFF',
	input: 'hsl(222, 30%, 97%)', // NOTE: can't use css var here. it error!
	inputSecondary: '#fecd62',
	tertiary: '#EFF4F5',
	text: '#604200',
	textDisabled: '#BDC2C4',
	textSubtle: 'hsl(47, 80%, 20%)', // NOTE: can't use css var here. it error!
	borderColor: '#E9EAEB',
	gradients: {
		bubblegum: `linear-gradient(139.73deg, var(--color-accent-100) 0%, var(--color-primary-100) 100%)`,
		inverseBubblegum: 'linear-gradient(139.73deg, #F3EFFF 0%, #E5FDFF 100%)',
		cardHeader: 'linear-gradient(111.68deg, #F2ECF2 0%, #E8F2F6 100%)',
		blue: 'linear-gradient(180deg, #A7E8F1 0%, #94E1F2 100%)',
		violet: 'linear-gradient(180deg, #E2C9FB 0%, #CDB8FA 100%)',
		violetAlt: 'linear-gradient(180deg, #CBD7EF 0%, #9A9FD0 100%)',
	},
}

export const darkColors: Colors = {
	...baseColors,
	...brandColors,
	secondary: '#9A6AFF',
	background: '#08060B',
	backgroundDisabled: '#3c3742',
	backgroundAlt: '#27262c',
	cardBorder: '#383241',
	contrast: '#FFFFFF',
	dropdown: '#1E1D20',
	dropdownDeep: '#100C18',
	invertedContrast: '#191326',
	input: '#372F47',
	inputSecondary: '#262130',
	primaryDark: '#0098A1',
	tertiary: '#353547',
	text: '#F4EEFF',
	textDisabled: '#666171',
	textSubtle: '#B8ADD2',
	borderColor: '#524B63',
	gradients: {
		bubblegum: 'linear-gradient(139.73deg, #313D5C 0%, #3D2A54 100%)',
		inverseBubblegum: 'linear-gradient(139.73deg, #3D2A54 0%, #313D5C 100%)',
		cardHeader: 'linear-gradient(166.77deg, #3B4155 0%, #3A3045 100%)',
		blue: 'linear-gradient(180deg, #00707F 0%, #19778C 100%)',
		violet: 'linear-gradient(180deg, #6C4999 0%, #6D4DB2 100%)',
		violetAlt: 'linear-gradient(180deg, #434575 0%, #66578D 100%)',
	},
}
