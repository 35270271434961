import {
	Currency,
	currencyEquals,
	ETHER,
	JSBI,
	Token,
	TokenAmount,
	WETH,
} from '@agent13/sdk-rei'
import { SAFEMOON_TOKEN_ADDRESS } from 'constants/lists'
import { BigNumber, ethers } from 'ethers'
import { useMemo } from 'react'
import { tryParseAmount } from '../state/swap/hooks'
import { useTransactionAdder } from '../state/transactions/hooks'
import { useCurrencyBalance } from '../state/wallet/hooks'
import { useActiveWeb3React } from './index'
import { tokenInfo } from './Tokens'
import { useWETHContract, useWSafeTokenContract } from './useContract'

export enum WrapType {
	NOT_APPLICABLE,
	WRAP,
	UNWRAP,
}

const NOT_APPLICABLE = { wrapType: WrapType.NOT_APPLICABLE }
/**
 * Given the selected input and output currency, return a wrap callback
 * @param inputCurrency the selected input currency
 * @param outputCurrency the selected output currency
 * @param typedValue the user input value
 */
export default function useWrapCallback(
	inputCurrency: Currency | undefined,
	outputCurrency: Currency | undefined,
	typedValue: string | undefined,
	inputAddress?: string,
	outputAddress?: string,
	inputAmountOverride?: string,
): { wrapType: WrapType; execute?: undefined | (() => Promise<void>); inputError?: string } {
	const { chainId, account } = useActiveWeb3React()

	const inputToken = inputAddress ? tokenInfo(inputAddress) : null
	const outputToken = outputAddress ? tokenInfo(outputAddress) : null
	const wSafeAddress =
		inputToken && outputToken
			? inputToken.tokenInfo.isWSafeToken
				? inputToken.address
				: outputToken.tokenInfo.isWSafeToken
				? outputToken.address
				: null
			: null

	const wethContract = useWETHContract()
	const wSafeTokenContract = useWSafeTokenContract(
		wSafeAddress ?? SAFEMOON_TOKEN_ADDRESS[chainId],
	)
	const balance = useCurrencyBalance(account ?? undefined, inputCurrency)
	// we can always parse the amount typed as the input currency, since wrapping is 1:1
	let divAmount = '1'
	if (inputCurrency) {
		for (let i = 0; i < 18 - inputCurrency.decimals; i++) {
			divAmount += '0'
		}
	}
	const inputAmount = useMemo(
		() =>
			inputCurrency instanceof Token && inputAmountOverride
				? new TokenAmount(
						inputCurrency as Token,
						JSBI.BigInt(
							BigNumber.from(ethers.utils.parseEther(inputAmountOverride))
								.div(divAmount)
								.toString(),
						),
				  )
				: tryParseAmount(typedValue, inputCurrency),
		[inputCurrency, inputAmountOverride, typedValue],
	)
	const addTransaction = useTransactionAdder()

	// console.log(inputAmount)

	return useMemo(() => {
		if (!wethContract || !chainId || !inputCurrency || !outputCurrency || !inputAmount)
			return NOT_APPLICABLE

		const sufficientBalance = inputAmount && balance && !balance.lessThan(inputAmount)

		if (inputCurrency === ETHER && currencyEquals(WETH[chainId], outputCurrency)) {
			return {
				wrapType: WrapType.WRAP,
				execute:
					sufficientBalance && inputAmount
						? async () => {
								try {
									const txReceipt = await wethContract.deposit({
										value: `0x${inputAmount.raw.toString(16)}`,
									})
									addTransaction(txReceipt, {
										summary: `Wrap ${inputAmount.toSignificant(6)} BNB to WBNB`,
									})
								} catch (error) {
									console.error('Could not deposit', error)
								}
						  }
						: undefined,
				inputError: sufficientBalance ? undefined : 'Insufficient BNB balance',
			}
		}

		if (currencyEquals(WETH[chainId], inputCurrency) && outputCurrency === ETHER) {
			return {
				wrapType: WrapType.UNWRAP,
				execute:
					sufficientBalance && inputAmount
						? async () => {
								try {
									const txReceipt = await wethContract.withdraw(
										`0x${inputAmount.raw.toString(16)}`,
									)
									addTransaction(txReceipt, {
										summary: `Unwrap ${inputAmount.toSignificant(
											6,
										)} WBNB to BNB`,
									})
								} catch (error) {
									console.error('Could not withdraw', error)
								}
						  }
						: undefined,
				inputError: sufficientBalance ? undefined : 'Insufficient WBNB balance',
			}
		}

		if (inputAddress && outputAddress) {
			// WRAP
			if (outputToken.tokenInfo.isWSafeToken) {
				return {
					wrapType: WrapType.WRAP,
					execute:
						sufficientBalance && inputAmount
							? async () => {
									try {
										const txReceipt = await wSafeTokenContract.wrap(
											`0x${inputAmount.raw.toString(16)}`,
										)
										addTransaction(txReceipt, {
											summary: `Wrap ${inputAmount.toSignificant(6)} ${
												inputCurrency.symbol
											} to ${outputCurrency.symbol}`,
										})
									} catch (error) {
										console.error('Could not deposit', error)
									}
							  }
							: undefined,
					inputError: sufficientBalance
						? undefined
						: `Insufficient ${inputCurrency.symbol} balance`,
				}
			}

			// UNWRAP
			if (inputToken.tokenInfo.isWSafeToken) {
				return {
					wrapType: WrapType.UNWRAP,
					execute:
						sufficientBalance && inputAmount
							? async () => {
									try {
										const txReceipt = await wSafeTokenContract.unwrap(
											`0x${inputAmount.raw.toString(16)}`,
										)
										addTransaction(txReceipt, {
											summary: `Unwrap ${inputAmount.toSignificant(6)} ${
												inputCurrency.symbol
											} to ${outputCurrency.symbol}`,
										})
									} catch (error) {
										console.error('Could not deposit', error)
									}
							  }
							: undefined,
					inputError: sufficientBalance
						? undefined
						: `Insufficient ${inputCurrency.symbol} balance`,
				}
			}
		}

		return NOT_APPLICABLE
	}, [
		wethContract,
		chainId,
		inputCurrency,
		outputCurrency,
		inputAmount,
		balance,
		addTransaction,
		inputAddress,
		outputAddress,
		inputAmountOverride,
	])
}
