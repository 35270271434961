import { Token } from '@agent13/sdk-rei'
import { transparentize } from 'polished'
import { Button, Text } from '../../uikit-foodcourt'
import React, { useCallback, useMemo, useState } from 'react'
import styled from 'styled-components'
import { AlertTriangle } from 'react-feather'
import useI18n from 'hooks/useI18n'
import { useActiveWeb3React } from '../../hooks'
import { useAllTokens } from '../../hooks/Tokens'
import { getBscScanLink, shortenAddress } from '../../utils'
import { ExternalLink } from '../Shared'
import CurrencyLogo from '../CurrencyLogo'
import Modal from '../Modal'
import { AutoRow, RowBetween } from '../Row'
import { AutoColumn } from '../Column'

const Wrapper = styled.div<{ error: boolean }>`
	background: ${({ theme }) => transparentize(0.6, theme.colors.tertiary)};
	padding: 0.75rem;
	border-radius: 20px;
`

const WarningContainer = styled.div`
	max-width: 420px;
	width: 100%;
	padding: 1rem;
	background: rgba(242, 150, 2, 0.05);
	border: 1px solid #f3841e;
	border-radius: 20px;
	overflow: auto;
`

const StyledWarningIcon = styled(AlertTriangle)`
	stroke: ${({ theme }) => theme.colors.failure};
`

interface TokenWarningCardProps {
	token?: Token
}

function TokenWarningCard({ token }: TokenWarningCardProps) {
	const { chainId } = useActiveWeb3React()
	const TranslateString = useI18n()
	const tokenSymbol = token?.symbol?.toLowerCase() ?? ''
	const tokenName = token?.name?.toLowerCase() ?? ''

	const allTokens = useAllTokens()

	const duplicateNameOrSymbol = useMemo(() => {
		if (!token || !chainId) return false

		return Object.keys(allTokens).some((tokenAddress) => {
			const userToken = allTokens[tokenAddress]
			if (userToken.equals(token)) {
				return false
			}
			return (
				userToken.symbol?.toLowerCase() === tokenSymbol ||
				userToken.name?.toLowerCase() === tokenName
			)
		})
	}, [token, chainId, allTokens, tokenSymbol, tokenName])

	if (!token) return null

	return (
		<Wrapper error={duplicateNameOrSymbol}>
			<AutoRow gap="6px">
				<AutoColumn gap="24px">
					<CurrencyLogo currency={token} size="16px" />
					<div> </div>
				</AutoColumn>
				<AutoColumn gap="10px" justify="flex-start">
					<Text>
						{token && token.name && token.symbol && token.name !== token.symbol
							? `${token.name} (${token.symbol})`
							: token.name || token.symbol}{' '}
					</Text>
					{chainId && (
						<ExternalLink
							style={{ fontWeight: 400 }}
							href={getBscScanLink(chainId, token.address, 'token')}
						>
							<Text title={token.address}>
								{shortenAddress(token.address)}{' '}
								{TranslateString(116, '(View on ReiScan)')}
							</Text>
						</ExternalLink>
					)}
				</AutoColumn>
			</AutoRow>
		</Wrapper>
	)
}

export default function TokenWarningModal({
	isOpen,
	tokens,
	onConfirm,
}: {
	isOpen: boolean
	tokens: Token[]
	onConfirm: () => void
}) {
	const [understandChecked, setUnderstandChecked] = useState(false)
	const toggleUnderstand = useCallback(() => setUnderstandChecked((uc) => !uc), [])
	const TranslateString = useI18n()

	const handleDismiss = useCallback(() => null, [])
	return (
		<Modal isOpen={isOpen} onDismiss={handleDismiss} maxHeight={90}>
			<WarningContainer className="token-warning-container">
				<AutoColumn gap="lg">
					<AutoRow gap="6px">
						<StyledWarningIcon />
						<Text color="failure">{TranslateString(1128, 'Token imported')}</Text>
					</AutoRow>
					<Text>
						{TranslateString(
							1130,
							'Anyone can create a BEP20 token on BSC with any name, including creating fake versions of existing tokens and tokens that claim to represent projects that do not have a token.',
						)}
					</Text>
					<Text>
						{TranslateString(
							1132,
							'This interface can load arbitrary tokens by token addresses. Please take extra caution and do your research when interacting with arbitrary BEP20 tokens.',
						)}
					</Text>
					<Text>
						{TranslateString(
							1134,
							'If you purchase an arbitrary token, you may be unable to sell it back.',
						)}
					</Text>
					{tokens.map((token) => (
						<TokenWarningCard key={token.address} token={token} />
					))}
					<RowBetween>
						<div>
							<label
								htmlFor="understand-checkbox"
								style={{ cursor: 'pointer', userSelect: 'none' }}
							>
								<input
									id="understand-checkbox"
									type="checkbox"
									className="understand-checkbox"
									checked={understandChecked}
									onChange={toggleUnderstand}
								/>{' '}
								<Text as="span" ml="4px">
									{TranslateString(148, 'I understand')}
								</Text>
							</label>
						</div>
						<Button
							disabled={!understandChecked}
							variant="danger"
							style={{ width: '140px' }}
							className="token-dismiss-button"
							onClick={() => {
								onConfirm()
							}}
						>
							{TranslateString(150, 'Continue')}
						</Button>
					</RowBetween>
				</AutoColumn>
			</WarningContainer>
		</Modal>
	)
}
