import { Percent } from '@agent13/sdk-rei'
import { ALLOWED_PRICE_IMPACT_HIGH, PRICE_IMPACT_WITHOUT_FEE_CONFIRM_MIN } from '../../constants'

/**
 * Given the price impact, get user confirmation.
 *
 * @param priceImpactWithoutFee price impact of the trade without the fee.
 */
export default function confirmPriceImpactWithoutFee(priceImpactWithoutFee: Percent): boolean {
	if (!priceImpactWithoutFee.lessThan(PRICE_IMPACT_WITHOUT_FEE_CONFIRM_MIN)) {
		return (
			window.prompt(
				`This swap has a price impact of at least ${PRICE_IMPACT_WITHOUT_FEE_CONFIRM_MIN.toFixed(
					0,
				)}%. Please type the word "confirm" to continue with this swap.`,
			) === 'confirm'
		)
	}
	if (!priceImpactWithoutFee.lessThan(ALLOWED_PRICE_IMPACT_HIGH)) {
		return window.confirm(
			`This swap has a price impact of at least ${ALLOWED_PRICE_IMPACT_HIGH.toFixed(
				0,
			)}%. Please confirm that you would like to continue with this swap.`,
		)
	}
	return true
}
