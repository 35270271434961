import { Currency, ETHER, Token } from '@agent13/sdk-rei'
import React, {
	KeyboardEvent,
	RefObject,
	useCallback,
	useContext,
	useEffect,
	useMemo,
	useRef,
	useState,
} from 'react'
import { Text, CloseIcon } from '../../uikit-foodcourt'
import { useSelector } from 'react-redux'
import { useTranslation } from 'react-i18next'
import { FixedSizeList } from 'react-window'
import { ThemeContext } from 'styled-components'
import AutoSizer from 'react-virtualized-auto-sizer'
import useI18n from 'hooks/useI18n'
import { useActiveWeb3React } from '../../hooks'
import { AppState } from '../../state'
import { useAllTokens, useToken } from '../../hooks/Tokens'
import { useSelectedListInfo } from '../../state/lists/hooks'
import { LinkStyledButton } from '../Shared'
import { isAddress } from '../../utils'
import Card from '../Card'
import Column from '../Column'
import ListLogo from '../ListLogo'
import QuestionHelper from '../QuestionHelper'
import Row, { RowBetween } from '../Row'
import CommonBases from './CommonBases'
import CurrencyList from './CurrencyList'
import { filterTokens } from './filtering'
import SortButton from './SortButton'
import { useTokenComparator } from './sorting'
import { PaddedColumn, SearchInput, Separator } from './styleds'

interface CurrencySearchProps {
	isOpen: boolean
	onDismiss: () => void
	selectedCurrency?: Currency | null
	onCurrencySelect: (currency: Currency) => void
	otherSelectedCurrency?: Currency | null
	showCommonBases?: boolean
	onChangeList: () => void
	tokenMode?: string
}

export function CurrencySearch({
	selectedCurrency,
	onCurrencySelect,
	otherSelectedCurrency,
	showCommonBases,
	onDismiss,
	isOpen,
	onChangeList,
	tokenMode = 'all',
}: CurrencySearchProps) {
	const { t } = useTranslation()
	const { chainId } = useActiveWeb3React()
	const theme = useContext(ThemeContext)

	const fixedList = useRef<FixedSizeList>()
	const [searchQuery, setSearchQuery] = useState<string>('')
	const [invertSearchOrder, setInvertSearchOrder] = useState<boolean>(false)
	const allTokens = useAllTokens(tokenMode)

	// if they input an address, use it
	const isAddressSearch = isAddress(searchQuery)
	const searchToken = useToken(searchQuery)

	const showETH: boolean = useMemo(() => {
		if (tokenMode !== 'all') return false
		const s = searchQuery.toLowerCase().trim()
		return s === '' || s === 'b' || s === 'bn' || s === 'bnb'
	}, [searchQuery])

	const tokenComparator = useTokenComparator(invertSearchOrder)

	const audioPlay = useSelector<AppState, AppState['user']['audioPlay']>(
		(state) => state.user.audioPlay,
	)

	const filteredTokens: Token[] = useMemo(() => {
		if (isAddressSearch) return searchToken ? [searchToken] : []
		return filterTokens(Object.values(allTokens), searchQuery, tokenMode)
	}, [isAddressSearch, searchToken, allTokens, searchQuery])

	const filteredSortedTokens: Token[] = useMemo(() => {
		if (searchToken) return [searchToken]
		const sorted = filteredTokens.sort(tokenComparator)
		const symbolMatch = searchQuery
			.toLowerCase()
			.split(/\s+/)
			.filter((s) => s.length > 0)
		if (symbolMatch.length > 1) return sorted

		return [
			...(searchToken ? [searchToken] : []),
			// sort any exact symbol matches first
			...sorted.filter((token) => token.symbol?.toLowerCase() === symbolMatch[0]),
			...sorted.filter((token) => token.symbol?.toLowerCase() !== symbolMatch[0]),
		]
	}, [filteredTokens, searchQuery, searchToken, tokenComparator])

	const handleCurrencySelect = useCallback(
		(currency: Currency) => {
			onCurrencySelect(currency)
			onDismiss()
			if (audioPlay) {
				const audio = document.getElementById('bgMusic') as HTMLAudioElement
				if (audio) {
					audio.play()
				}
			}
		},
		[onDismiss, onCurrencySelect, audioPlay],
	)

	// clear the input on open
	useEffect(() => {
		if (isOpen) setSearchQuery('')
	}, [isOpen])

	// manage focus on modal show
	const inputRef = useRef<HTMLInputElement>()
	const handleInput = useCallback((event) => {
		const input = event.target.value
		const checksummedInput = isAddress(input)
		setSearchQuery(checksummedInput || input)
		fixedList.current?.scrollTo(0)
	}, [])

	const handleEnter = useCallback(
		(e: KeyboardEvent<HTMLInputElement>) => {
			if (e.key === 'Enter') {
				const s = searchQuery.toLowerCase().trim()
				if (s === 'bnb') {
					handleCurrencySelect(ETHER)
				} else if (filteredSortedTokens.length > 0) {
					if (
						filteredSortedTokens[0].symbol?.toLowerCase() ===
							searchQuery.trim().toLowerCase() ||
						filteredSortedTokens.length === 1
					) {
						handleCurrencySelect(filteredSortedTokens[0])
					}
				}
			}
		},
		[filteredSortedTokens, handleCurrencySelect, searchQuery],
	)

	const selectedListInfo = useSelectedListInfo()
	const TranslateString = useI18n()
	return (
		<Column style={{ width: '100%', flex: '1 1' }}>
			<PaddedColumn gap="14px">
				<RowBetween>
					<Text>
						{TranslateString(82, 'Select a token')}
						<QuestionHelper
							text={TranslateString(
								128,
								'Find a token by searching for its name or symbol or by pasting its address below.',
							)}
						/>
					</Text>
					<CloseIcon onClick={onDismiss} />
				</RowBetween>
				<SearchInput
					type="text"
					id="token-search-input"
					placeholder={t('tokenSearchPlaceholder')}
					value={searchQuery}
					ref={inputRef as RefObject<HTMLInputElement>}
					onChange={handleInput}
					onKeyDown={handleEnter}
				/>
				{showCommonBases && (
					<CommonBases
						chainId={chainId}
						onSelect={handleCurrencySelect}
						selectedCurrency={selectedCurrency}
					/>
				)}
				<RowBetween>
					<Text fontSize="14px">{TranslateString(126, 'Token name')}</Text>
					<SortButton
						ascending={invertSearchOrder}
						toggleSortOrder={() => setInvertSearchOrder((iso) => !iso)}
					/>
				</RowBetween>
			</PaddedColumn>

			<Separator />

			<div style={{ flex: '1' }}>
				<AutoSizer disableWidth>
					{({ height }) => (
						<CurrencyList
							height={height}
							showETH={showETH}
							currencies={filteredSortedTokens}
							onCurrencySelect={handleCurrencySelect}
							otherCurrency={otherSelectedCurrency}
							selectedCurrency={selectedCurrency}
							fixedListRef={fixedList}
						/>
					)}
				</AutoSizer>
			</div>

			{null && (
				<>
					<Separator />
					<Card>
						<RowBetween>
							{selectedListInfo.current ? (
								<Row>
									{selectedListInfo.current.logoURI ? (
										<ListLogo
											style={{ marginRight: 12 }}
											logoURI={selectedListInfo.current.logoURI}
											alt={`${selectedListInfo.current.name} list logo`}
										/>
									) : null}
									<Text id="currency-search-selected-list-name">
										{selectedListInfo.current.name}
									</Text>
								</Row>
							) : null}
							<LinkStyledButton
								style={{
									fontWeight: 500,
									color: theme.colors.textSubtle,
									fontSize: 16,
								}}
								onClick={onChangeList}
								id="currency-search-change-list-button"
							>
								{selectedListInfo.current
									? TranslateString(180, 'Change')
									: TranslateString(1152, 'Select a list')}
							</LinkStyledButton>
						</RowBetween>
					</Card>
				</>
			)}
		</Column>
	)
}

export default CurrencySearch
