import { useEffect } from 'react'
import useGetPriceData from './useGetPriceData'
import { COUPON } from '../constants'
import { ChainId } from '@agent13/sdk-rei'

const useGetDocumentTitlePrice = () => {
	const priceData = useGetPriceData()
	console.log(priceData)
	const cakePriceUsd = priceData
		? parseFloat(priceData[COUPON[ChainId.MAINNET].address.toLowerCase()].price)
		: 0

	const cakePriceUsdString =
		Number.isNaN(cakePriceUsd) || cakePriceUsd === 0
			? ''
			: ` - $${cakePriceUsd.toLocaleString(undefined, {
					minimumFractionDigits: 3,
					maximumFractionDigits: 3,
			  })}`

	useEffect(() => {
		// document.title = `Foodcourt${cakePriceUsdString}`
		// document.title = `Foodcourt Finance`
	}, [cakePriceUsdString])
}
export default useGetDocumentTitlePrice
