import { ChainId, JSBI, Percent, Token, WETH } from '@agent13/sdk-rei'

export const ROUTER_ADDRESS = process.env.REACT_APP_ROUTER_ADDRESS
export const FACTORY_ADDRESS = process.env.REACT_APP_FACTORY_ADDRESS
export const INIT_CODE_HASH = process.env.REACT_APP_INIT_CODE_HASH

// console.log(ROUTER_ADDRESS)

// a list of tokens by chain
type ChainTokenList = {
	readonly [chainId in ChainId]: Token[]
}

export const COUPON = {
	[ChainId.MAINNET]: new Token(
		ChainId.MAINNET,
		'0xbc09220a8e461880dbe5517ecf53bc1b12caa05d',
		18,
		'COUPON',
		'Coupon Token',
	),
	[ChainId.BSCTESTNET]: new Token(
		ChainId.BSCTESTNET,
		'0x0E09FaBB73Bd3Ade0a17ECC321fD13a19e81cE82',
		18,
		'COUPON',
		'Coupon Token',
	),
}

export const KMATIC = {
	[ChainId.MAINNET]: new Token(
		ChainId.MAINNET,
		'0x032574B64Bf6fa42951f836CC8c5099d1C5747d3',
		18,
		'kMatic',
		'KillSwitch-Peg MATIC Token',
	),
}

export const WBNB = {
	[ChainId.MAINNET]: new Token(
		ChainId.MAINNET,
		'0xbb4CdB9CBd36B01bD1cBaEBF2De08d9173bc095c',
		18,
		'WBNB',
		'Wrapped BNB',
	),
	[ChainId.BSCTESTNET]: new Token(
		ChainId.BSCTESTNET,
		'0x094616F0BdFB0b526bD735Bf66Eca0Ad254ca81F',
		18,
		'WBNB',
		'Wrapped BNB',
	),
}
export const DAI = {
	[ChainId.MAINNET]: new Token(
		ChainId.MAINNET,
		'0x1AF3F329e8BE154074D8769D1FFa4eE058B1DBc3',
		18,
		'DAI',
		'Dai Stablecoin',
	),
	[ChainId.BSCTESTNET]: new Token(
		ChainId.BSCTESTNET,
		'0x1AF3F329e8BE154074D8769D1FFa4eE058B1DBc3',
		18,
		'DAI',
		'Dai Stablecoin',
	),
}
export const BUSD = {
	[ChainId.MAINNET]: new Token(
		ChainId.MAINNET,
		'0xDD2bb4e845Bd97580020d8F9F58Ec95Bf549c3D9',
		18,
		'BUSD',
		'Binance USD',
	),
	[ChainId.BSCTESTNET]: new Token(
		ChainId.BSCTESTNET,
		'0xDD2bb4e845Bd97580020d8F9F58Ec95Bf549c3D9',
		18,
		'BUSD',
		'Binance USD',
	),
}
export const BTCB = {
	[ChainId.MAINNET]: new Token(
		ChainId.MAINNET,
		'0x7130d2A12B9BCbFAe4f2634d864A1Ee1Ce3Ead9c',
		18,
		'BTCB',
		'Binance BTC',
	),
	[ChainId.BSCTESTNET]: new Token(
		ChainId.BSCTESTNET,
		'0x7130d2A12B9BCbFAe4f2634d864A1Ee1Ce3Ead9c',
		18,
		'BTCB',
		'Binance BTC',
	),
}
export const USDT = {
	[ChainId.MAINNET]: new Token(
		ChainId.MAINNET,
		'0x55d398326f99059fF775485246999027B3197955',
		18,
		'USDT',
		'Tether USD',
	),
	[ChainId.BSCTESTNET]: new Token(
		ChainId.BSCTESTNET,
		'0xE02dF9e3e622DeBdD69fb838bB799E3F168902c5',
		18,
		'USDT',
		'Tether USD',
	),
}
export const KUSDC = {
	[ChainId.MAINNET]: new Token(
		ChainId.MAINNET,
		'0xa72A60a908E6CFD89FeEC670E332422E3f3655ae',
		18,
		'kUSDC',
		'KillSwitch Pegged USDC',
	),
	[ChainId.BSCTESTNET]: new Token(
		ChainId.BSCTESTNET,
		'0xa72A60a908E6CFD89FeEC670E332422E3f3655ae',
		18,
		'kUSDC',
		'KillSwitch Pegged USDC',
	),
}
export const UST = {
	[ChainId.MAINNET]: new Token(
		ChainId.MAINNET,
		'0x23396cF899Ca06c4472205fC903bDB4de249D6fC',
		18,
		'UST',
		'Wrapped UST Token',
	),
	[ChainId.BSCTESTNET]: new Token(
		ChainId.BSCTESTNET,
		'0x23396cF899Ca06c4472205fC903bDB4de249D6fC',
		18,
		'UST',
		'Wrapped UST Token',
	),
}
export const ETH = {
	[ChainId.MAINNET]: new Token(
		ChainId.MAINNET,
		'0x2170Ed0880ac9A755fd29B2688956BD959F933F8',
		18,
		'ETH',
		'Binance-Peg Ethereum Token',
	),
	[ChainId.BSCTESTNET]: new Token(
		ChainId.BSCTESTNET,
		'0x2170Ed0880ac9A755fd29B2688956BD959F933F8',
		18,
		'ETH',
		'Binance-Peg Ethereum Token',
	),
}
export const KUMA = {
	[ChainId.MAINNET]: new Token(
		ChainId.MAINNET,
		'0xbf2C56466213F553Fcf52810fE360dFe29E88471',
		18,
		'KUMA',
		'Kururu Kuma',
	),
	[ChainId.BSCTESTNET]: new Token(
		ChainId.BSCTESTNET,
		'0xbf2C56466213F553Fcf52810fE360dFe29E88471',
		18,
		'KUMA',
		'Kururu Kuma',
	),
}
export const REP = {
	[ChainId.MAINNET]: new Token(
		ChainId.MAINNET,
		'0x4CfAFCF8280f6dd8e350Fa34d6ee00F6F468374a',
		18,
		'REP',
		'REI Point Token',
	),
	[ChainId.BSCTESTNET]: new Token(
		ChainId.BSCTESTNET,
		'0x4CfAFCF8280f6dd8e350Fa34d6ee00F6F468374a',
		18,
		'KUMA',
		'REI Point Token',
	),
}

const WETH_ONLY: ChainTokenList = {
	[ChainId.MAINNET]: [WETH[ChainId.MAINNET]],
	[ChainId.BSCTESTNET]: [WETH[ChainId.BSCTESTNET]],
}

// used to construct intermediary pairs for trading
export const BASES_TO_CHECK_TRADES_AGAINST: ChainTokenList = {
	...WETH_ONLY,
	[ChainId.MAINNET]: [
		...WETH_ONLY[ChainId.MAINNET],
		BUSD[ChainId.MAINNET],
		USDT[ChainId.MAINNET],
		KMATIC[ChainId.MAINNET],
		KUSDC[ChainId.MAINNET],
		COUPON[ChainId.MAINNET],
		KUMA[ChainId.MAINNET],
		REP[ChainId.MAINNET],
	],
	[ChainId.BSCTESTNET]: [
		...WETH_ONLY[ChainId.BSCTESTNET],
		DAI[ChainId.BSCTESTNET],
		BUSD[ChainId.BSCTESTNET],
		BTCB[ChainId.BSCTESTNET],
		USDT[ChainId.BSCTESTNET],
		UST[ChainId.BSCTESTNET],
		ETH[ChainId.BSCTESTNET],
	],
}

/**
 * Some tokens can only be swapped via certain pairs, so we override the list of bases that are considered for these
 * tokens.
 */
export const CUSTOM_BASES: { [chainId in ChainId]?: { [tokenAddress: string]: Token[] } } = {
	[ChainId.MAINNET]: {},
}

// used for display in the default list when adding liquidity
export const SUGGESTED_BASES: ChainTokenList = {
	...WETH_ONLY,
	[ChainId.MAINNET]: [
		...WETH_ONLY[ChainId.MAINNET],
		DAI[ChainId.MAINNET],
		BUSD[ChainId.MAINNET],
		USDT[ChainId.MAINNET],
	],
	[ChainId.BSCTESTNET]: [
		...WETH_ONLY[ChainId.BSCTESTNET],
		DAI[ChainId.BSCTESTNET],
		BUSD[ChainId.BSCTESTNET],
		USDT[ChainId.BSCTESTNET],
	],
}

// used to construct the list of all pairs we consider by default in the frontend
export const BASES_TO_TRACK_LIQUIDITY_FOR: ChainTokenList = {
	...WETH_ONLY,
	[ChainId.MAINNET]: [
		...WETH_ONLY[ChainId.MAINNET],
		DAI[ChainId.MAINNET],
		BUSD[ChainId.MAINNET],
		BTCB[ChainId.MAINNET],
		USDT[ChainId.MAINNET],
	],
	[ChainId.BSCTESTNET]: [
		...WETH_ONLY[ChainId.BSCTESTNET],
		DAI[ChainId.BSCTESTNET],
		BUSD[ChainId.BSCTESTNET],
		BTCB[ChainId.BSCTESTNET],
		USDT[ChainId.BSCTESTNET],
	],
}

export const PINNED_PAIRS: { readonly [chainId in ChainId]?: [Token, Token][] } = {
	[ChainId.MAINNET]: [
		[COUPON[ChainId.MAINNET], WBNB[ChainId.MAINNET]],
		[BUSD[ChainId.MAINNET], USDT[ChainId.MAINNET]],
		[DAI[ChainId.MAINNET], USDT[ChainId.MAINNET]],
	],
	[ChainId.BSCTESTNET]: [
		[COUPON[ChainId.BSCTESTNET], WBNB[ChainId.BSCTESTNET]],
		[BUSD[ChainId.BSCTESTNET], USDT[ChainId.BSCTESTNET]],
		[DAI[ChainId.BSCTESTNET], USDT[ChainId.BSCTESTNET]],
	],
}

export const NetworkContextName = 'NETWORK'

// default allowed slippage, in bips
export const INITIAL_ALLOWED_SLIPPAGE = 80
// 20 minutes, denominated in seconds
export const DEFAULT_DEADLINE_FROM_NOW = 60 * 20

// one basis point
export const ONE_BIPS = new Percent(JSBI.BigInt(1), JSBI.BigInt(10000))
export const BIPS_BASE = JSBI.BigInt(10000)
// used for warning states
export const ALLOWED_PRICE_IMPACT_LOW: Percent = new Percent(JSBI.BigInt(100), BIPS_BASE) // 1%
export const ALLOWED_PRICE_IMPACT_MEDIUM: Percent = new Percent(JSBI.BigInt(300), BIPS_BASE) // 3%
export const ALLOWED_PRICE_IMPACT_HIGH: Percent = new Percent(JSBI.BigInt(500), BIPS_BASE) // 5%
// if the price slippage exceeds this number, force the user to type 'confirm' to execute
export const PRICE_IMPACT_WITHOUT_FEE_CONFIRM_MIN: Percent = new Percent(
	JSBI.BigInt(1000),
	BIPS_BASE,
) // 10%
// for non expert mode disable swaps above this
export const BLOCKED_PRICE_IMPACT_NON_EXPERT: Percent = new Percent(JSBI.BigInt(1500), BIPS_BASE) // 15%

// used to ensure the user doesn't send so much ETH so they end up with <.01
export const MIN_ETH: JSBI = JSBI.exponentiate(JSBI.BigInt(10), JSBI.BigInt(16)) // .01 ETH
